<template>
  <div>
    <section
      class="container flex-nowrap px-0 mx-auto tw-mt-5 talent-sign-up-custom-wrapper sign-up row d-flex flex-lg-row justify-content-center flex-column-reverse align-items-center"
    >
      <div class="px-0 sign-up-form col-lg-12">
        <form @submit.prevent="submit">
          <div class="row">
            <div class="mt-0 mb-3 col-md-6">
              <label class="mb-1 tw-text-gray-800">
                {{ $t("signUp.firstName") }}
              </label>
              <input
                class="px-2 w-100 inputStyle"
                v-model.trim="$v.user.first_name.$model"
                :placeholder="$t('signUp.firstName')"
              />

              <!-- ********* Validation Error*********** -->

              <VulidateError
                :validationField="$v.user.first_name"
                :params="[
                  'required',
                  'alphaNumericWithSpecialChars',
                  { maxLength: { number: '50' } },
                ]"
              />
              <!-- ************************************* -->
            </div>

            <div class="mt-0 mb-3 col-md-6">
              <label class="mb-1 tw-text-gray-800">
                {{ $t("signUp.lastName") }}
              </label>
              <input
                class="px-2 w-100 inputStyle"
                v-model="$v.user.last_name.$model"
                :placeholder="$t('signUp.lastName')"
              />
              <!-- ********* Validation Error*********** -->
              <VulidateError
                :validationField="$v.user.last_name"
                :params="[
                  'required',
                  'alphaNumericWithSpecialChars',
                  { maxLength: { number: '50' } },
                ]"
              />
              <!-- ************************************* -->
            </div>
          </div>

          <div class="mb-3">
            <label class="mb-1 tw-text-gray-800">
              {{ $t("signUp.emailAddress") }}
            </label>
            <input
              class="px-2 w-100 inputStyle"
              v-model="$v.user.email.$model"
              :placeholder="$t('signUp.emailAddress')"
            />
            <!-- ********* Validation Error*********** -->
            <VulidateError :validationField="$v.user.email" :params="[]" />
            <!-- ************************************* -->
          </div>
          <div class="mt-3 mb-1 row">
            <div
              class="mb-3 col-6 custom-width-talent-phone talent-signup-country-select"
            >
              <label class="mb-1 tw-text-gray-800">
                {{ $t("talent.countryOfResidence") }}
              </label>
              <!-- <country-select
                  v-model="user.country"
                  :country="user.country"
                  topCountry="Saudi Arabia"
                  countryName
                  removePlaceholder
                  class="px-2 country-select w-100"
                /> -->
                <SelectCountry
                  :placeholder="$t('talent.countryOfResidence')"
                  :validationField="$v.user.country"
                  hideLabel="true"
                  :selectOptions="countryList"
                  keySelected="id"
                />
<!--               <Select
                :placeholder="$t('talent.countryOfResidence')"
                :validationField="$v.user.country"
                hideLabel="true"
                :selectOptions="countryList"
                inputStyle="country-select w-100 px-2"
                keySelected="name"
              /> -->
            </div>
            <div class="mb-3 col-6 custom-width-talent-phone">
              <label class="mb-1 tw-text-gray-800 required">
                {{ $t("signUp.phone") }}
              </label>
              <div id="phone-number">
                <VuePhoneNumberInput
                  v-model="user.phone"
                  size="sm"
                  class="w-100"
                  :translations="{
                      countrySelectorLabel: $t('signup.countryCode'),
                      phoneNumberLabel: $t('signup.phoneNumber'),
                    }"
                  @update="updatePhoneNumber"
                  @phone-number-blur="validatePhoneNumber"
                  :default-country-code="defaultCountryCode"
                  error-color="#FF0000"
                  :no-example="true"
                />
              </div>
            </div>
          </div>
          <div class="row mx-0">
            <div
              v-if="phoneNumberAlreadyRegistered"
              class="mb-3 tw-flex tw-flex-row tw-bg-[#FF6F591A] py-3 px-4 tw-rounded-xl tw-items-center tw-gap-2 tw-mb-4 tw-text-[#414042] tw-text-lg tw-font-medium tw-leading-snug tw-w-full"
            >
              <img
                loading="lazy"
                class="tw-flex tw-h-5 tw-w-5"
                src="@/assets/images/info1.svg"
              />
              <p class="tw-text-[#414042] tw-text-sm">
                {{ $t("talentWizard.phoneUsedBefore") }}
              </p>
            </div>
            <div
              v-else-if="isInvalidPhoneNumber"
              class="mb-3 tw-flex tw-flex-row tw-bg-[#FF6F591A] py-3 px-4 tw-rounded-xl tw-items-center tw-gap-2 tw-mb-4 tw-text-[#414042] tw-text-lg tw-font-medium tw-leading-snug tw-w-full"
            >
              <img
                loading="lazy"
                class="tw-flex tw-h-5 tw-w-5"
                src="@/assets/images/info1.svg"
              />
              <p class="tw-text-[#414042] tw-text-sm">
                {{ $t("talentWizard.invalidPhoneNumber") }}
              </p>
            </div>
          </div>

          <div class="mb-3 row">
            <div class="col-12">
              <label class="mb-1 tw-text-gray-800">
                {{ $t("signUp.role") }}
              </label>
              <TalentRolesDDL
                :validationField="$v.user.role_id"
                hideLabel="true"
                inputStyle="country-select px-2"
              />
            </div>
          </div>
          <div class="mt-2">
            <button
              type="submit"
              class="w-100 sign-up-btn"
              :disabled="!validForm"
            >
              {{ $t("signUp.signUp") }}
            </button>
          </div>
        </form>
      </div>

      <OTPModal
        v-show="isModalVisible"
        @close="isModalVisible = false"
        @handleOtpResend="handleOtpResend($event)"
        :remainingOTP="remainingOTP"
        :email="user.email"
        :in_competition="in_competition"
      />
    </section>

     <p class="mx-4 text-center tw-mt-5">
          <span class="already-a-user tw-text-[#5c5c5c]  regular-poppins-font">
            {{ $t("signUp.alreadyUser") }} &nbsp;</span
          >
          <router-link
            :to="{ name: 'Login' }"
            class="sign-in text-decoration-none regular-poppins-font log-btn tw-text-primary tw-font-semibold"
          >
            {{ $t("signUp.signIn") }}</router-link
          >
        </p>
    
    <div
      class="my-4 d-flex justify-content-between align-items-baseline"
      v-if="!hideGoogleBtn"
    >
      <div class="horizontal-line-black"></div>
      <div class="tw-text-[#808285] small">
        {{ $t("signUp.orSignUpWith") }}
      </div>
      <div class="horizontal-line-black"></div>
    </div>
    <div class="mt-2 !tw-flex row justify-content-center" v-if="!hideGoogleBtn">
      <GoogleAuthBtn type="1" :new_talent="true" />
    </div>

     <p class="mt-4 text-center tw-mb-4">
          <span class="already-a-user md:tw-text-lg tw-text-base !tw-text-[#494d55] regular-poppins-font">
            {{ $t("signUp.wantToHireTalent") }} &nbsp;</span
          >
          <router-link
            :to="{ name: 'client-signup' }"
            class="sign-in text-decoration-none regular-poppins-font md:tw-text-lg tw-text-base log-btn tw-text-primary tw-font-semibold"
          >
            {{ $t("signUp.signupAsClient") }}</router-link
          >
     </p>
  </div>
</template>
<script>
import { required, maxLength, email } from "vuelidate/lib/validators";
import { alphaNumericWithSpecialChars } from "../../utils/utils";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VulidateError from "../../components/shared/VulidateError/vulidateError.vue";
import OTPModal from "../../components/OTPModal/index.vue";
import GoogleAuthBtn from "../../components/shared/googleAuthBtn/googleAuthBtn.vue";
//import Select from "../../components/shared/formControls/select/select.vue";
import TalentRolesDDL from "../../components/shared/talentRolesDDL/talentRolesDDL.vue";
import "./talentSignup.scss";
import SelectCountry from "@/pages/Talent/profile/components/editPersonalInfo/selectCountry.vue";
import store from "../../store";

export default {
  name: "talentSignup",
  props: ["hideGoogleBtn", "in_competition"],
  mounted() {
    this.$store.dispatch("shared/getCountriesRequest");
  },
  data: function () {
    return {
      isModalVisible: false,
      phoneNumberAlreadyRegistered: false,
      isInvalidPhoneNumber: false,
      defaultCountryCode: "SA",
      user: {
        first_name: "",
        last_name: "",
        email: "",
        country:  187 ,
        role_id: "",
        phone: null,
        country_code: null,
        type: 1,
      },
      errorPhone: "",
      remainingOTP: null
    };
  },
  validations: {
    user: {
      first_name: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      last_name: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      country: {},
      role_id: {
        required,
      },
    },
  },
  methods: {
    validatePhoneNumber() {
      if (!this.user.phone) return;
      this.axios
        .post("/api/auth/validate-phone", {
          phone: this.user.phone,
          country_code: this.user.country_code
        })
        .then(() => {
          this.isInvalidPhoneNumber = false;
          this.errorPhone = false;
          this.phoneNumberAlreadyRegistered = false;
        })
        .catch((err) => {
          if (err) {
            if (err == "Please enter a valid phone number" || err === "خطا في رقم الهاتف") {
              this.isInvalidPhoneNumber = true;
              this.errorPhone = true;
              this.phoneNumberAlreadyRegistered = false;
            } else if (err == "This phone number is already registered" || err == "رقم الهاتف مستخدم بالفعل") {
              this.phoneNumberAlreadyRegistered = true;
              this.errorPhone = true
              this.isInvalidPhoneNumber = false;
            }
          }
          console.error(err);
        });
    },
    handleOtpResend($event) {
      this.remainingOTP = $event;
    },
    updatePhoneNumber(data) {
      this.user.country_code = data.countryCallingCode
        ? "+".concat(data.countryCallingCode)
        : null;
      this.errorPhone = !data.isValid;
    },
    submit() {
      let loader = this.$loading.show({});
      let payload = { ...this.user };
      payload.country = this.countryList.find((countr) => countr.id == payload.country )?.name_en
      
      
      payload = { 
        ...payload, 
        marketing_campaign: this.$route.query.Campaign,
        signupSource: store.getters["shared/getUTMParameters"],
      };
      if (this.in_competition) {
        payload.in_competition = 1;
      }

      this.$store
        .dispatch("auth/register", payload)
        .then((res) => {
          localStorage.setItem("new_talent", true);
          this.remainingOTP = res.otp
          this.isModalVisible = true;

          if (window.gtag) window.gtag("event", "Talent sign-up submitted", {
            step_number: 'Step 1',
            signup_method: 'Email',
            user_id: res.id,
            first_name: payload.first_name,
            last_name: payload.last_name,
            phone_number: payload.phone,
            country: payload.country,
            country_code: payload.country_code,
            email: payload.email
          });
        }).catch((err) => {
          if (err.response?.status == 422 && err.response.data.errors[0] && err.response.data.errors[0].type == "otp") {
           localStorage.setItem("new_talent", true);
           this.remainingOTP = 0
           this.isModalVisible = true;   
          }
         
        })
        .finally(() => loader.hide());
    },
    handleCloseModal() {
      this.isModalVisible = false;
      this.$emit("handleCloseModal");
    },
    handleSignupView() {
      this.$emit("handleView", "signup");
    },
    handleOpenSuccessModal() {
      this.$emit("handleView", "success");
    },
  },
  computed: {
    console: () => console,
    countryList() {
      return this.$store.getters["shared/getCountryList"].map(country => ({...country,  defaultCountryName:country.name, name: this.$i18n.locale == "en" ? country.name_en : country.name_ar, } ))
    },
    validForm: function () {
      return !this.phoneNumberAlreadyRegistered &&
        !this.isInvalidPhoneNumber &&
        !this.errorPhone &&
        !this.$v.$invalid;
    },
  },
  watch: {
    "user.phone": function (v) {
      return (this.user.phone = this.user.phone ? v.replace(/\s/g, "") : null);
    },
    "user.country": {
      handler(newVal) {
        let countrySelected = this.countryList?.find(
          (ele) => ele.name === newVal
        );
        this.defaultCountryCode = countrySelected?.code;
      },
      deep: true,
    },
  },
  components: {
    VuePhoneNumberInput,
    VulidateError,
    OTPModal,
    GoogleAuthBtn,
    /* Select */
    TalentRolesDDL,
    SelectCountry
  },
};
</script>

<style scoped src="./signup.css"></style>


